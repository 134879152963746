import includes from "lodash/includes";
import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { IonButton, IonIcon, IonInput } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

import "./WLFormInput.scss";
import { UPPERCASE_FIELDS } from "../../../utils/common";

// Get all props from IonInput then removed className and onIonChange to override
type OverriddentIonInputProps = Omit<
  React.ComponentProps<typeof IonInput>,
  "className" | "onIonChange" | "value"
>;

// see https://ionicframework.com/docs/api/input for the IonInput props
export interface WLFormInputProps extends Partial<OverriddentIonInputProps> {
  name: string;
  noMargin?: boolean;
  title?: string;
  className?: string;
  rules?: RegisterOptions;
  label?: string;
  allCaps?: boolean;
  darkMode?: boolean;
  customOnChange?: (value: string | null | undefined) => void;
}

/**
 * !Before using this component:
 * - Make sure parent is wrapped in <FormProvider></FormProvider>
 * - Make sure useForm methods is passed in FormProvider. Example:
 *  const useFormMethods = useForm({
 *   mode: "all",
 *   reValidateMode: "onChange",
 * });
 * <FormProvider {...useFormMethods}>
 *  <WLFormInput/>
 */

export const WLFormInput = (props: WLFormInputProps) => {
  const {
    name,
    noMargin,
    title,
    className,
    rules,
    label,
    type,
    allCaps,
    darkMode,
    children,
    customOnChange,
    ...remainingProps
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);

  const hasErrors = !!errors && errors[name] && !isEmpty(errors[name]?.message);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => {
        return (
          <div className="wl-input-cta">
            <IonInput
              {...remainingProps}
              aria-labelledby={`${name}-label`}
              type={
                (type === "password" && showPassword) || type === "email"
                  ? "text"
                  : type
              }
              mode="md"
              onBlur={onBlur}
              onIonBlur={onBlur}
              className={`wl-form-input ${darkMode && "dark-mode"} ${
                includes(UPPERCASE_FIELDS, name) && "ion-text-capitalize"
              } ${hasErrors && "ion-invalid"} ${true && "ion-touched"} ${
                noMargin ? "no-margin" : ""
              } ${type} ${className !== undefined ? className : ""} ${
                rules !== undefined && rules.required !== undefined
                  ? "required"
                  : ""
              } ${allCaps && "all-caps"}`} // !workaround istouched in react-hook-form not working??
              {...(includes(UPPERCASE_FIELDS, name) && {
                autocapitalize: "words",
              })}
              value={value || ""}
              onIonInput={({ detail: { value } }) => {
                onChange(value);
                if (customOnChange !== undefined) {
                  customOnChange(value);
                }
              }}
              fill="outline"
              {...(label !== undefined && {
                label,
                labelPlacement: "stacked",
              })}
              errorText={
                hasErrors ? (errors[name]!.message as string) : "Input invalid"
              }
            >
              {children}
            </IonInput>
            {type === "password" && (
              <IonButton
                fill="clear"
                className="password-helper-btn ion-no-padding ion-no-margin"
                color="dark"
                onClick={() => setShowPassword(!showPassword)}
              >
                <IonIcon icon={showPassword ? eyeOutline : eyeOffOutline} />
              </IonButton>
            )}
          </div>
        );
      }}
      rules={rules}
    />
  );
};
