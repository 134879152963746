import cx from "classnames";
import isNull from "lodash/isNull";
import { memo } from "react";
import { IonImg, IonLabel } from "@ionic/react";

import s from "./styles.module.scss";
import stripeTrusted from "../../../assets/images/wl-security-badge-stripe.png";
import useWebSignupStore from "../store";
import { useStepSubscriptionProvider } from "./provider";
import { FormProvider } from "react-hook-form";
import { WLButton } from "../../../../Common/components/WLButton/WLButton";
import { WLSkeleton } from "../../../../Common/components/WLSkeleton/WLSkeleton";
import StripeForm from "./StripePaymentForm/StripeForm";
import PromoCodeSection from "./PromoCodeSection/PromoCodeSection";
import StripeSubscriptionOverview from "./StripeSubscriptionOverview/StripeSubscriptionOverview";
import ChooseMembership from "./ChooseMembership/ChooseMembership";

const StepSubscription = () => {
  const { selectedSubscription, stripeClientSecretId, submitPayment } =
    useWebSignupStore();
  const {
    promoField,
    validatePromoCode,
    useFormMethods,
    updateSelectedSubscription,
  } = useStepSubscriptionProvider();

  return (
    <div className={s.container}>
      <IonLabel className="wl-h2-winnersans primary normal">CHECKOUT</IonLabel>

      <div className={s.form}>
        {stripeClientSecretId !== undefined ? (
          !isNull(stripeClientSecretId) ? (
            <StripeForm clientSecret={stripeClientSecretId} />
          ) : (
            <IonLabel className="wl-body-1 danger normal">
              Something went wrong please try again.
            </IonLabel>
          )
        ) : (
          <>
            <WLSkeleton width="100%" height="300px" />
          </>
        )}
        <PromoCodeSection
          promoField={promoField}
          validatePromoCode={validatePromoCode}
          useFormMethods={useFormMethods}
        />
        <StripeSubscriptionOverview />
        <WLButton
          disabled={
            selectedSubscription === undefined ||
            isNull(selectedSubscription) ||
            stripeClientSecretId === undefined ||
            isNull(stripeClientSecretId)
          }
          onClick={submitPayment}
          className={cx(s.submitBtn, "wl-body-6 red-submit-button")}
          isFormComponent
        >
          Sign Up
        </WLButton>
        <IonLabel className={cx(s.note, "wl-body-2-gilroy")}>
          Cancel anytime, No lock ins
        </IonLabel>
      </div>
      <IonImg className={s.stripeTrusted} src={stripeTrusted} />

      <ChooseMembership updateSubscription={updateSelectedSubscription} />
    </div>
  );
};

export default memo(StepSubscription);
