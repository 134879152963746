import cx from "classnames";
import { arrowBackOutline } from "ionicons/icons";
import { IonButton, IonIcon, IonLabel, IonProgressBar } from "@ionic/react";

import s from "./SignUpStepsHeader.module.scss";
import { WLCOLORS } from "../../../../../Common/config/global";
import useWebSignupStore, { WEB_SIGNUP_STEPS } from "../../store";

export const SignUpStepsHeader = () => {
  const { step, setStep } = useWebSignupStore();
  return (
    <div className={s.container}>
      {step > WEB_SIGNUP_STEPS.email && (
        <IonButton
          onClick={() => {
            setStep(step - 1);
          }}
          className={s.backBtn}
          fill="clear"
          color={WLCOLORS.dark}
        >
          <IonIcon icon={arrowBackOutline} />
        </IonButton>
      )}

      <div
        className={cx(s.progress, {
          [s.onStep1]: step === WEB_SIGNUP_STEPS.email,
        })}
      >
        <IonLabel className={s.label}>{`Step ${step} of ${
          Object.keys(WEB_SIGNUP_STEPS).length
        }`}</IonLabel>
        <IonProgressBar
          value={step / Object.keys(WEB_SIGNUP_STEPS).length}
          className={s.progressBar}
        ></IonProgressBar>
      </div>
    </div>
  );
};
