import ReactFbq from "react-fbq";
import { IonContent, IonImg, IonPage } from "@ionic/react";

import s from "./WebSignUp.module.scss";
import brandLogo from "../../../Common/assets/images/brand-logo.svg";
import blueSmiley from "../../assets/icons/blue-smiley.svg";
import useWebSignupStore, { WEB_SIGNUP_STEPS } from "./store";
import { isMobile } from "../../../Common/utils/common";
import { SignUpStepsHeader } from "./components/SignUpStepsHeader/SignUpStepsHeader";
import { Suspense, lazy } from "react";
import StepSubscription from "./StepSubscription";

const StepEmailSetup = lazy(() => import("./StepEmailSetup"));
const StepPasswordSetup = lazy(() => import("./StepPasswordSetup"));
const StepCompleteProfile = lazy(() => import("./StepCompleteProfile"));
const SignUpFooter = lazy(
  () => import("../../../Common/components/SignUpFooter/SignUpFooter")
);

(ReactFbq as any).initialize({ id: "3689540701275716" });
(ReactFbq as any).pageView();
export const WebSignUp = () => {
  const { step } = useWebSignupStore();

  const renderStepComponent = () => {
    switch (step) {
      case WEB_SIGNUP_STEPS.email:
        return <StepEmailSetup />;
      case WEB_SIGNUP_STEPS.password:
        return <StepPasswordSetup />;
      case WEB_SIGNUP_STEPS.completeProfile:
        return <StepCompleteProfile />;
      case WEB_SIGNUP_STEPS.subscription:
        return <StepSubscription />;
      default:
        return <></>;
    }
  };
  return (
    <IonPage className={s.page}>
      <IonContent className={s.content} fullscreen>
        {!isMobile(true) ? (
          <IonImg src={brandLogo} className={s.brandLogo} />
        ) : (
          <></>
        )}

        <div className={s.main}>
          <div className={s.container}>
            {!isMobile(true) ? (
              <IonImg src={blueSmiley} className={s.smileyIcon} />
            ) : (
              <></>
            )}
            <SignUpStepsHeader />
            <div className={s.stepsContainer}>
              <Suspense>{renderStepComponent()}</Suspense>
            </div>
          </div>
        </div>
        <div className={s.footer}>
          <Suspense>
            <SignUpFooter />
          </Suspense>
        </div>
      </IonContent>
    </IonPage>
  );
};
