import isEmpty from "lodash/isEmpty";
import { arrowBackOutline, close } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
  IonImg,
  IonBackButton,
} from "@ionic/react";

import cx from "classnames";
import s from "./WLCommonBasicHeader.module.scss";

import { WLCOLORS } from "../../config/global";
import { LANDING } from "../../constants/routes";

interface WLCommonBasicHeaderProps {
  className?: string;
  title?: string;
  overrideBackRoute?: string;
  overrideBackFunction?: () => void;
  onClickClose?: () => void;
  onClickSkip?: () => void;
  noBackButton?: boolean;

  headerClassName?: string;
}

export const WLCommonBasicHeader = (props: WLCommonBasicHeaderProps) => {
  const {
    className,
    title,
    overrideBackRoute,
    overrideBackFunction,
    onClickClose,
    onClickSkip,
    noBackButton,
    headerClassName,
  } = props;
  return (
    <IonHeader className={cx(s.wlV2Header, headerClassName)}>
      <IonToolbar
        className={cx(s.wlHeaderToolbar, "wl-header-gilroy", className)}
      >
        <IonButtons className={s.wlHeaderStartSlotButtons} slot="start">
          {overrideBackRoute !== undefined &&
          !isEmpty(overrideBackRoute) &&
          !noBackButton ? (
            <IonButton
              color="dark"
              routerLink={overrideBackRoute}
              routerDirection="back"
            >
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          ) : overrideBackFunction !== undefined && !noBackButton ? (
            <IonButton color="dark" onClick={overrideBackFunction}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          ) : !noBackButton ? (
            <IonBackButton
              color="dark"
              text=""
              icon={arrowBackOutline}
              defaultHref={LANDING}
            />
          ) : (
            <></>
          )}
        </IonButtons>
        {title && (
          <div style={{ width: "110%", textAlign: "center" }}>
            <IonLabel>{title}</IonLabel>
          </div>
        )}
        {onClickClose && (
          <IonButtons slot="end">
            <IonButton onClick={onClickClose} fill="clear" color="dark">
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        )}
        {onClickSkip && (
          <IonButtons slot="end">
            <IonButton
              className="wl-h4 normal"
              onClick={onClickSkip}
              fill="clear"
              color="dark"
            >
              SKIP
            </IonButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};
